<template>
  <div class="flex column center" style="margin-bottom:4rem;">
    <div class="new_box">
      <div class="titlee">武汉市司法局关太兵局长一行莅临福建历思司法鉴定所调研！</div>
      <p class="time">2019.11.01</p>
      <p class="space_content" >
        2019年10月31日，武汉市司法局关太兵局长、聂德宗副局长等领导莅临福建历思司法鉴定所（以下简称“历思”）进行调研指导。
      </p>
      <p class="space_content" >
        关太兵局长一行参观了历思物证中心实验室并听取了历思发展的介绍。在双方座谈会上，就司法鉴定机构发展和鉴定服务质量等问题进行深入地探讨。
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/26-1.png')">
      </div>

      <p class="space_content" >
        福建历思司法鉴定所历经十多年发展，逐步建立起一个声誉良好的司法鉴定品牌。目前拥有3100平方米大型物证中心（1100平方米技术科研中心、1500平方米国家标准实验室）、国内资质最齐全的司法鉴定机构之一（17项司法鉴定资质，基本覆盖司法鉴定各个领域），可以说历思的成长历程正是我国司法鉴定行业发展的一个缩影。
      </p>
      <p class="space_content" >
        历思长期致力于发展智慧物证科技，为社会提供可信鉴定服务，目前在文书痕迹、声像资料、微量物证鉴定等多个方面，其软硬件条件和检验技术已达国内先进水平；特别是在毒品鉴定、食药环鉴定、文物艺术品鉴定等领域已成为行业引导者。
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/26-2.png')">
      </div>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/26-3.png')">
      </div>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/26-4.png')">
      </div>
      <p class="space_content" >
        在座谈会上，关太兵局长对历思发展定位、科研方向、人才培养、运行管理等方面给予了高度评价，并表示历思实验室建设、档案管理、技术研发给其留下深刻的印象，收获很大，希望双方今后能进一步沟通探讨 ，开展鉴定机构交流活动等建设性合作。
      </p>


    </div>
    <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex" style="margin-right:10%">
          <p style="white-space:nowrap">上一篇：</p>
          <router-link class="a" :to="'/news/lsnews/25'"><p class="ellipsis1">庆贺！历思物证科技产研体系成员企业——堃晟，通过了“CMA”授权颁发的珠宝玉石及贵金属的能力资格认证！A”授权颁发的珠宝玉石及贵金属的能力资格认证！</p>
          </router-link>
        </div>
      </div>
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex">
          <p style="white-space:nowrap">下一篇：</p>
          <router-link class="a" :to="'/news/lsnews/27'"><p class="ellipsis1">庆贺！福建历思司法鉴定所党支部与厦门市税务局第二稽查局检查三科党支部签订党建共建协议！</p>
          </router-link>

        </div>
      </div>
      <div class="col2 flex  end1 center">
        <router-link class="a" style="color:#fff" :to="'/news/lsnews'">
          <el-button type="primary">返回列表</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
}
</script>
<style>
.redText {
  background: red;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.titleText {
  font-size: 20px;
  font-weight: 600;
}
</style>
